import * as React from 'react';

import { FAQ } from 'corigan';

import { useFAQSource } from 'staticqueries';

const PageContents: React.FC = () => {
  const data = useFAQSource();
  return <FAQ data={data} />;
};

export default PageContents;
