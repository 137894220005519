import { useStaticQuery, graphql } from 'gatsby';

export const useNewsSource = () => {
  const { allFile } = useStaticQuery(
    graphql`
      query NewsFiles {
        allFile {
          nodes {
            extension
            id
            internal {
              content
            }
            relativePath
          }
        }
      }
    `,
  );

  const filesArray = allFile.nodes;
  const hasFiles = filesArray && filesArray.length > 0;
  if (!hasFiles) return [];

  const newsFiles = filesArray.filter(file => {
    const { extension, relativePath } = file;
    const validExtension = extension === `md` || extension === `mdx`;
    if (!validExtension) return false;

    const isAtNewsPath = relativePath.startsWith(`news`);
    if (!isAtNewsPath) return false;

    return true;
  });

  const hasNewsFiles = newsFiles && newsFiles.length > 0;
  if (!hasNewsFiles) return [];

  return newsFiles;
};

export default useNewsSource;
